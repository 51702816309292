<template>
    <div class="action-schedule">
        <template>
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span>약속이 확정되었어요!</span>
                </div>
                <div class="message-content">
                    <div class="desc" v-for="(d, idx) in desc" :key="idx" :class="{ 'li-dot': desc.length > 1 }">
                        <!-- <div :class="{'li-dot' : desc.length > 1}" v-html="''"/> -->
                        <!-- <div> -->
                        <div v-html="d"></div>
                        <!-- </div> -->
                    </div>
                    <div class="grey-box m-t-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                    <div class="grey-box m-t-8">
                        <div class="title-grey">약속 내용</div>
                        <!--   하드코딩  -->
                        <div class="content-grey">
                            <p class="m-b-4">{{ time }}</p>
                            <p>{{ content.meet_place }}에서 {{ meetType }}예정</p>
                        </div>
                    </div>
                    <div
                        class="pink-box m-t-8 p-l-16 m-b-8"
                        v-if="isMale && content.meet_type_confirmed !== 'coffee' && !content.restaurant_name"
                    >
                        <div class="title-pink">여성분이 꺼리는 음식</div>
                        <div class="content-pink">{{ foodPrefer }}</div>
                    </div>
                    <div v-else class="m-b-8"></div>
                    <div v-if="content.restaurant_name" class="green-box m-t-8 m-b-8 p-l-16">
                        <div class="title-green">장소</div>
                        <div class="content-green m-r-4">
                            <div class="m-r-4">{{ content.restaurant_name }}</div>
                            <div class="place-map">
                                <img
                                    :src="require('@/assets/images/icons/location.png')"
                                    alt=""
                                    widtt="16"
                                    height="16"
                                />
                                <div @click="showMap">지도보기</div>
                            </div>
                        </div>
                    </div>
                    <BottomButton
                        :nonFixed="true"
                        v-if="isMale && !content.restaurant_name"
                        :label="'만남장소 입력하기'"
                        @click="openModalMeetingPlaceInput"
                        :disabled="isDisabled || insertComplete"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleComplete',
    props: ['message'],
    data: () => ({
        insertComplete: false,
    }),
    computed: {
        isMale() {
            return this.$store.getters.me.gender === 0
        },
        desc() {
            if (this.isMale) {
                if (this.content.restaurant_name) {
                    return [
                        '일정 / 위치 변경 시, 장소가 같더라도 반드시 다시 예약 및 확인을 진행해주세요.',
                        '만남 하루 전 오전 11시 30분에 비상연락용 대화방이 앱 내에 생성될 예정입니다.',
                    ]
                } else {
                    return [
                        '장소를 직접 예약하신 후에, 장소를 입력해주세요. 장소를 입력하시면 상대에게 안내 sms가 발송됩니다. <br> <span style="color: #F64F56; font-size: 10px; font-style: italic; line-height: 1px;"> *유의사항* 약속 이틀 전까지 장소를 입력하지 않으면 만남 의사가 없는 것으로 간주하고 약속이 자동 취소처리되며, 미진행 수수료 2만원이 부과됩니다. </span> ',
                        '만남 하루 전 오전 11시 30분에 비상연락용 대화방이 앱 내에 생성될 예정입니다.',
                    ]
                }
            } else {
                if (this.content.restaurant_name) {
                    return [
                        '일정 / 위치 변경 시, 장소가 같더라도 반드시 다시 예약 및 확인을 진행해주세요.',
                        '만남 하루 전 오전 11시 30분에 비상연락용 대화방이 앱 내에 생성될 예정입니다.',
                    ]
                } else {
                    return [
                        '구체적인 레스토랑/카페는 남성분이 예약 후 안내드릴 예정입니다. (장소가 입력되면 SMS를 보내드리고, ‘약속 관리’ 메뉴에서도 확인이 가능합니다.)',
                        '만남 하루 전 오전 11시 30분에 비상연락용 대화방이 앱 내에 생성될 예정입니다.',
                    ]
                }
            }
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
        // isSelfReservation() {
        //     return this.content.self_reservation === 1
        // },
        time() {
            const dateConfirmed = this.content.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            const time = this.$moment(dateConfirmed).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        meetType() {
            const fixed = ['coffee', 'meal']

            return fixed.includes(this.content.meet_type_confirmed)
                ? this.$translate(this.content.meet_type_confirmed.toUpperCase())
                : '커피 또는 식사'
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
        isDisabled() {
            return this.content.status >= 1
        },
    },
    methods: {
        async openModalMeetingPlaceInput() {
            const isSuccess = await this.$modal.custom({
                component: 'ModalMeetingPlaceInput',
                options: {
                    schedule_id: this.content.schedule_id,
                },
            })
            if (isSuccess === '성공') {
                this.insertComplete = true
            }
        },
        showMap() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.schedule.restaurant_url,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
    &.li-dot {
        display: list-item;
        align-items: flex-start;
        margin-left: 16px;
        margin-bottom: 10px;
    }
}
.content-green {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    .place-map {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 4px;
        min-width: 69px;
        width: fit-content;
        height: 24px;
        padding: 2px 4px;
        background: #03006e;
        border-radius: 100px;
    }
}
</style>
